import React from 'react';
import {imageProps, imageDescription} from './data';
import {Container, Image, Ul} from '../../../Components/Atom';
import * as S from './style';
import {BreadCrumb} from '../../../Components/Template';
import {expiredIMG} from '../../../config/images';

import styled from 'styled-components';
import {viewSize, colorPalette} from '../../../config';

export const ExpiredImg = styled.img`
	width: 50%;
	@media screen and (max-width: ${viewSize.mobile}) {
		width: 100%;
	}
`;

export const ExpiredText = styled.div`
	color: ${colorPalette.primary};
	font-family: 'Gmarket';
	font-size: 35px;
	font-weight: 600;
	text-align: center;
	@media screen and (max-width: ${viewSize.mobile}) {
		font-size: 20px;
	}
`;

const Gift = () => {
	return (
		<Container>
			<BreadCrumb depths={['HOME', '대회안내', '기념품']} />
			<S.Container>
				{/* <ExpiredImg src={expiredIMG} />
				<ExpiredText>준비중입니다.</ExpiredText> */}
				<S.ImageWrapper>
					<Image {...imageProps} />
				</S.ImageWrapper>
				{/* <S.UlWrapper>
					<Ul {...imageDescription} />
				</S.UlWrapper> */}
			</S.Container>
		</Container>
	);
};

export default Gift;
