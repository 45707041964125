import {colorPalette} from '../../../config';
import {winnerImg} from '../../../config/images';
import {ulsContentProps} from '../../common';

export const imageProps = {
	src: winnerImg,
	alt: '역대 수상자',
};

// export const imageDescription = {
// 	items: [
// 		{
// 			content: {
// 				...ulsContentProps,
// 				children: '색상은 랜덤으로 제공됩니다.',
// 			},
// 		},
// 		{
// 			content: {
// 				...ulsContentProps,
// 				children:
// 					'기념품은 사전 발송 없이 현장 참석자들에게만 완주 후 지급합니다.(추후 발송도 없음)',
// 			},
// 		},
// 	],
// };
