import {Row} from 'antd';
import React from 'react';
import {isMobile} from 'react-device-detect';
import styled from 'styled-components';
import {colorPalette} from '../../../config';
import {Button, Ul} from '../../Atom';
import {
	RadioForm,
	SelectForm,
	InputForm,
	GroupForm,
	PhoneInput,
	EmailInput,
	InputNumberForm,
} from '../../Organism';
import * as S from './style';
import V2GroupTable from './V2GroupTable';

const InputGenerator = ({input, info, setInfo}) => {
	switch (input.type) {
		case 'email':
			return <EmailInput {...input} info={info} setInfo={setInfo} />;
		case 'phone':
			return <PhoneInput {...input} info={info} setInfo={setInfo} />;
		case 'input':
			return <InputForm {...input} />;
		case 'radio':
			return <RadioForm {...input} />;
		case 'select':
			return <SelectForm {...input} />;
		case 'inputnumber':
			return <InputNumberForm {...input} info={info} setInfo={setInfo} />;
	}
};

const PersonForm = ({
	terms,
	uls,
	inputs,
	button,
	group,
	isV2Group,
	newGroup,
	submitButton,
	setNewGroup,
	info,
	setInfo,
	isUpdate,
	isInfoText = false,
	vInfoText = false,
}) => {
	return (
		<S.Container>
			{terms && <S.TermsWrapper></S.TermsWrapper>}
			{uls && (
				<S.UlWrapper>
					<Ul {...uls} />
				</S.UlWrapper>
			)}
			{inputs.map((input, index, array) => {
				return (
					<S.InputWrapper key={index}>
						<InputGenerator
							input={input}
							info={info}
							setInfo={setInfo}
						/>
					</S.InputWrapper>
				);
			})}

			{group && (
				<>
					{isV2Group ? (
						<>
							<V2GroupTable
								newGroup={newGroup}
								setNewGroup={setNewGroup}
								isUpdate={isUpdate}
							/>
							<S.ButtonWrapper>
								<Button {...button} onClick={submitButton} />
							</S.ButtonWrapper>
						</>
					) : (
						<S.GroupFormWrapper>
							<GroupForm {...group} />
						</S.GroupFormWrapper>
					)}
				</>
			)}
			{!isV2Group && button && (
				<>
				{/* 기념품이 티셔츠가 될 경우를 대비하여 사이즈를 수집합니다. */}
					{isInfoText && (
						<InfoText>
							{isMobile ? (
								<>
									* 티셔츠 사이즈표는 기념품 안내페이지에 기재되어있습니다. 
									<br />* 비밀번호는 숫자 4자리로 설정 가능합니다.
								</>
							) : (
								<>
									* 티셔츠 사이즈표는 기념품 안내페이지에 기재되어있습니다.
									<br />
									<br />* 비밀번호는 숫자 4자리로 설정 가능합니다.
								</>
							)}
						</InfoText>
					)}

					{vInfoText && (
						<InfoText>
							작성하지 않을 시 봉사 시간이 부여되지 않을 수
							있습니다.
						</InfoText>
					)}

					<S.ButtonWrapper>
						<Button {...button} />
					</S.ButtonWrapper>
				</>
			)}
		</S.Container>
	);
};

export default PersonForm;

const InfoText = styled.p({
	color: colorPalette.primary,
	fontSize: 16,
});
