import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ImageWrapper = styled.div`
	margin-bottom: 1.5rem;
`;

export const UlWrapper = styled.div``;
