import {tdProps, thProps, cardTitleProps} from '../../common';

export const coursePrize = {
	title: {
		text: {children: '종목별 시상안내', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '부별'},
			{...thProps, children: '시상내역'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스 부문'},
				{...tdProps, children: '남자/여자'},
				{
					...tdProps,
					children:
						'1-3위: 상품권, 상장, 트로피  /  4-5위: 상장,트로피, 부상/ 6- 15위 : 상장, 부상',
				},
			],
			[
				{...tdProps, children: '하프코스 부문'},
				{...tdProps, children: '남자/여자'},
				{
					...tdProps,
					children:
						'1-3위: 상품권, 상장, 트로피  /  4-5위: 상장,트로피, 부상/ 6- 15위 : 상장, 부상',
				},
			],
			[
				{...tdProps, children: '10km코스 부문'},
				{...tdProps, children: '남자/여자'},
				{
					...tdProps,
					children:
						'1-3위: 상품권, 상장, 트로피  /  4-5위: 상장,트로피, 부상/ 6- 15위 : 상장, 부상',
				},
			],
			[
				{...tdProps, children: '단체'},
				{...tdProps, children: ''},
				{...tdProps, children: '교통지원금'},
			],
			[
				{...tdProps, children: '경품'},
				{...tdProps, children: ''},
				{...tdProps, children: '추첨'},
			],
		],
	},
};

export const prizeInfo = {
	title: {
		text: {children: '시상금 안내', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '순위'},
			{...thProps, children: '시상'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스 부문\n(남/여)', rowSpan: '5'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '40만원, 상장, 트로피'},
				{
					...tdProps,
					children: ``,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '30만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4-5위'},
				{...tdProps, children: '상장, 트로피, 부상'},
			],
			[
				{...tdProps, children: '6-15위'},
				{...tdProps, children: '상장, 부상'},
			],
			[
				{...tdProps, children: '하프코스 부문\n(남/여)', rowSpan: '5'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '20만원, 상장, 트로피'},
				{
					...tdProps,
					children: ``,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '15만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '10만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4-5위'},
				{...tdProps, children: '상장, 트로피, 부상'},
			],
			[
				{...tdProps, children: '6-15위'},
				{...tdProps, children: '상장, 부상'},
			],
			[
				{...tdProps, children: '10km코스 부문\n (남/여)', rowSpan: '5'},
				{...tdProps, children: '1위'},
				{...tdProps, children: '10만원, 상장, 트로피'},
				{
					...tdProps,
					children: ``,
					rowSpan: '5',
				},
			],
			[
				{...tdProps, children: '2위'},
				{...tdProps, children: '7만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '3위'},
				{...tdProps, children: '5만원, 상장, 트로피'},
			],
			[
				{...tdProps, children: '4-5위'},
				{...tdProps, children: '상장, 트로피, 부상'},
			],
			[
				{...tdProps, children: '6-15위'},
				{...tdProps, children: '상장, 부상'},
			],
			[
				{...tdProps, children: '단체참가 지원금'},
				{...tdProps, children: '5km 참가자 제외\n30명 이상'},
				{...tdProps, children: `15만원`},
				{...tdProps, children: ''},
			],
			[
				{...tdProps, children: '단체부스'},
				{...tdProps, children: '단체 총 참가자\n30명 이상'},
				{...tdProps, children: '부스 1동 제공'},
				{...tdProps, children: ''},
			],
			[
				{...tdProps, children: '경품 증정'},
				{
					...tdProps,
					children: '사전 추첨(행사장에서 확인)',
					colSpan: '2',
				},
			],
		],
		descriptions: [
			{
				content: {
					children: '5km 코스는 기록 미측정으로 시상에서 제외됩니다.',
				},
			},
			{
				content: {
					children:
						'남, 여 부문 종목별 참가자가 각 200명 이하일 경우 시상은 1 - 10위까지만 진행됩니다.',
				},
			},
		],
	},
};
