import {
	cardTitleProps,
	tdProps,
	thProps,
	tableDescriptionProps,
} from '../../common';
import {courseImg, highdegreemap} from '../../../config/images';
import {colorPalette} from '../../../config';

// export const imageProps = {
// 	src: courseImg,
// 	alt: '코스',
// 	borderRadius: '1.375rem',
// 	border: `1px solid ${colorPalette.border}`,
// };
// export const imagePropsTitle = {
// 	title: {
// 		text: {children: '대회코스', ...cardTitleProps},
// 	},
// };
export const imageProps = {
	title: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {children: '대회코스', ...cardTitleProps, type: 'p'},
	},
	course: {
		src: courseImg,
		alt: '코스',
		borderRadius: '1.375rem',
	},
	title1: {
		textAlign: 'left',
		bgColor: colorPalette.primary,
		height: '5px',
		text: {children: '고저도', ...cardTitleProps, type: 'p'},
	},
	course1: {
		src: highdegreemap,
		alt: '고저도',
		borderRadius: '1.375rem',
	},
};

export const limitTime = {
	title: {
		text: {children: '코스별 제한시간', ...cardTitleProps},
	},
	table: {
		ths: [
			{...thProps, children: '종목'},
			{...thProps, children: '출발시간'},
			{...thProps, children: '제한시간'},
			{...thProps, children: '구간'},
		],
		trs: [
			[
				{...tdProps, children: '풀코스'},
				{...tdProps, children: '09:00'},
				{...tdProps, children: '5시간(14:00)'},
				{...tdProps, children: '생활공원~하동화력(왕복)'},
			],
			[
				{...tdProps, children: '하프코스'},
				{...tdProps, children: '09:10'},
				{...tdProps, children: '3시간(12:10)'},
				{...tdProps, children: '생활공원∼섬진강휴게소 강 건너편(왕복)'},
			],
			[
				{...tdProps, children: '10km'},
				{...tdProps, children: '09:20'},
				{...tdProps, children: '2시간(11:20)'},
				{...tdProps, children: '생활공원∼섬진강습지공원(왕복)'},
			],
			[
				{...tdProps, children: '5km'},
				{...tdProps, children: '09:30'},
				{...tdProps, children: '1시간(10:30)'},
				{...tdProps, children: '생활공원∼현대오일뱅크주유소(왕복)'},
			],
		],
		descriptions: [
			{
				content: {
					...tableDescriptionProps,
					children: `제한 시간이 넘었음에도 불구하고 주최 측 회송차량 승차를 거부한 채 계속 주행하던 도중에 발생하는 사고에 대한 모든 책임은 참가자 본인에게 있으며, 주최 측은 책임을 지지 않습니다.`,
				},
			},
		],
	},
};
