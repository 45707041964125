import React, {useState} from 'react';
import {CardContent, SelectTable} from '../../../Components/Organism';
import {BreadCrumb, PersonForm} from '../../../Components/Template';
import {setForm, setWarnText} from '../../../util';
import {isValidateRecord} from '../../../util/validator';
import {firstProps, initialInfo, invalidProps, secondProps} from './data';
import {Container} from '../../../Components/Atom';
import {getRecordList} from '../../../api';
import dayjs from 'dayjs';

const Check = () => {
	const [info, setInfo] = useState(initialInfo);
	const [invalid, setInvalid] = useState(invalidProps);
	const [section, setSection] = useState(0);

	const firstInfo = {
		name: info.name,
		no: info.no,
		gender: info.gender,
		course: info.course,
	};

	firstProps.inputs.map((input) => {
		setForm(input, info, setInfo);
		setWarnText(input, invalid);
	});

	firstProps.button.onClick = async () => {
		if (isValidateRecord(firstInfo, invalidProps, setInvalid)) {
			let res = await getRecordList({...info});
			if (res.data.count == 0) {
				return alert('기록이 존재하지 않습니다.')
			}

			if (res.data.count === 1 && res.isSuccess) {
				secondProps.info[0][0].content.children = dayjs(res.data.results[0].year)?.format('YYYY') + '년';
				secondProps.info[1][0].content.children = res.data.results[0].name;
				secondProps.info[2][0].content.children = res.data.results[0].no;
				secondProps.info[3][0].content.children = res.data.results[0].full_record;
				if (res.data.results[0].half1_record) {
					secondProps.info[4][0].content.children = res.data.results[0].half1_record;
				}
				// secondProps.info[4][0].content.children = res.data.results[0].half1_record;
				if (res.data.results[0].half2_record) {
					secondProps.info[5][0].content.children = res.data.results[0].half2_record;
				}
				if (res.data.results[0].half3_record) {
					secondProps.info[6][0].content.children = res.data.results[0].half3_record;
				} else {
					secondProps.info.pop();
				}

				setSection(1);
			}
		}
	};

	return (
		<Container height={'80vh'}>
			<BreadCrumb depths={['HOME', '대회기록', '기록조회']} />

			{section === 0 && <PersonForm {...firstProps} />}
			{section === 1 && (
				<React.Fragment>
					<SelectTable items={secondProps.info} />
					<div style={{margin: '1rem 0'}}></div>
					<CardContent {...secondProps.cardContent} />
				</React.Fragment>
			)}
		</Container>
	);
};

export default Check;
