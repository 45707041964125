import React from 'react';
import {BreadCrumb, CardSection} from '../../../Components/Template';
import {imageProps} from './data';
import {parking} from './data';
import {Container, Map, Image} from '../../../Components/Atom';

import * as S from './style';
import {TitleList} from '../../../Components/Organism';

const Direction = () => {
	return (
		<Container>
			<BreadCrumb depths={['HOME', '대회안내', '오시는길']} />
			<S.Container>
				<S.MapWrapper>
					<Map />
				</S.MapWrapper>
				{/* <Image {...imageProps.image} />
				<br/>
				<S.CardSectionWrapper>
					<CardSection {...parking} />
				</S.CardSectionWrapper> */}
				{/* <S.CardSectionWrapper>
					<CardSection {...allocate} />
					</S.CardSectionWrapper>
					<S.TitleWrapper>
					{allocateDescription.map((description) => (
						<TitleList {...description} />
						))}
					</S.TitleWrapper> */}
			</S.Container>
		</Container>
	);
};

export default Direction;
